import { addNotification, removeNotification } from './uiSlice';

const moment = require('moment');


export const successNotification = (content) => {
    return async(dispatch) => {

        let id = moment().unix();

        dispatch(addNotification({ id, content, type: 'success' }));

        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 5000);

    }
}

export const infoNotification = (content) => {
    return async(dispatch) => {

        let id = moment().unix();

        dispatch(addNotification({ id, content, type: 'info' }));

        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 3000);

    }
}

export const warningNotification = (content) => {
    return async(dispatch) => {

        let id = moment().unix();

        dispatch(addNotification({ id, content, type: 'warning' }));

        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 3000);

    }
}

export const dangerNotification = (content) => {
    return async(dispatch) => {

        let id = moment().unix();

        dispatch(addNotification({ id, content, type: 'danger' }));

        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 3000);

    }
}