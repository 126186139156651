import React from 'react'

import { useConsulta } from './hooks/useConsulta';

import { ConsultaUpdate } from './components/ConsultaUpdate';

export const Consulta = () => {

    const { consultaQuery: { isLoading, isError, data: consulta } } = useConsulta();

    return (
        <>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <ConsultaUpdate consulta={consulta} />
                </>
                }
            </>
            }
        </>
    )
}
