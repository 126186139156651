import { Outlet, useLocation } from "react-router-dom";

import styles from './AuthLayout.module.css';

export const AuthLayout = () => {

    const location = useLocation();

    const css = `
        .notification-layout-margin{
            top: 10px !important;
        }
    `;

    return (
        <>
            <style>{ css }</style>
            <div className={"container-fluid " + styles.authContainer}>
                <div className="row">
                    <div className={((location.pathname === '/signup') ? "col-xl-3 " : "col-xl-6 " ) + ((location.pathname === '/signup') ? styles.elipseContainerSmall : styles.elipseContainer )}></div>
                    <div className={(location.pathname === '/signup') ? "col-xl-9 " : "col-xl-6 "}>
                        <div className={styles.formContainer}>
                            <div>
                                <div className="centrar">
                                    <div className={styles.formLogo}></div>
                                </div>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
