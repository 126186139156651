import { useState } from 'react';
import PropTypes from 'prop-types';

import { ConsultorioPreview } from './ConsultorioPreview';
import { ConsultorioUpdate } from './ConsultorioUpdate';

import styles from '../Consultorios.module.css';

export const Consultorio = ({ consultorio }) => {

    const [consultorioUpdateStatus, setConsultorioUpdateStatus] = useState(false);

    const handleEditarConsultorio = () => {
        setConsultorioUpdateStatus(true);
    }

    const handleCancelarEditarConsultorio = () => {
        setConsultorioUpdateStatus(false);
    }

    return (
        <div className={styles.panel}>
            {(!consultorioUpdateStatus)
                ? <ConsultorioPreview consultorio={consultorio} handleEditarConsultorio={handleEditarConsultorio} />
                : <ConsultorioUpdate consultorio={consultorio} handleCancelarEditarConsultorio={handleCancelarEditarConsultorio} />
            }
        </div>
    )
}

Consultorio.propTypes = {
    consultorio: PropTypes.object.isRequired
}