import { useState } from 'react';
import { useAsistentes } from './hooks/useAsistentes';

import { Asistente } from './components/Asistente';
import { AsistenteCreate } from './components/AsistenteCreate';
import { PrimaryButton } from '../../components';

import styles from './Asistentes.module.css';

export const Asistentes = () => {

  const { asistentesQuery: { isLoading, isError, data: asistentes } } = useAsistentes();

  const [asistenteCreateStatus, setAsistenteCreateStatus] = useState(false);

  const handleCrearAsistente = () => {
    setAsistenteCreateStatus(true);
  }

  const handleCancelarCrearAsistente = () => {
    setAsistenteCreateStatus(false);
  }

  return (
    <>
      <div className={styles.header}>Asistentes</div>
      {(isLoading)
        ? <>Cargando...</>
        : <>
            {(isError)
            ? <>Error</>
            : <>
              {asistentes.map(asistente => <Asistente key={asistente._id} asistente={asistente} />)}
              {(asistenteCreateStatus)
              ? <>
                <AsistenteCreate handleCancelarCrearAsistente={handleCancelarCrearAsistente} />
              </>
              : <>
                <div className='d-flex justify-content-end'>
                  <PrimaryButton
                    icon="Plus"
                    inverse={true}
                    marginTop={false}
                    onClick={handleCrearAsistente}
                    text="Agregar Asistente"
                    type="button"
                  />
                </div>
              </>
              }
            </>
            }
        </>
      }
    </>
  )
}
