import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePacientes } from './hooks/usePacientes';

import { IconButton, LimiteFilter, PaginationButton, PrimaryButton } from '../../components';
import { PacientesSearch } from './components/PacientesSearch/PacientesSearch';

import styles from './Pacientes.module.css';

export const Pacientes = () => {

  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');
  const [searchLimite, setSearchLimite] = useState('8');
  const [searchPag, setSearchPag] = useState(1);

  const { pacientesQuery: { isLoading, isFetching , isError, data: { desde, hasta, pacientes, pagina, total, total_paginas } } } = usePacientes({ searchString, searchLimite, searchPag });
  
  const prevPage = () => {
    if (searchPag === 1) {
      return;
    }

    setSearchPag(searchPag - 1);
  };

  const nextPage = () => {
    if (total_paginas <= searchPag) {
        return;
    }

    setSearchPag(searchPag + 1);
  };

  const handleCrearPaciente = () => {
    navigate("/paciente/nuevo");
  }

  const handleViewPaciente = (id) => {
    navigate(`/paciente/view/${id}`);
  }
  
  return (
    <>
      <div className="row">
        <div className="col">
          <div className={styles.panel + " " + styles.panelNoHeader}>
            {(isLoading)
              ? <>Cargando...</>
              : <>
                {(isError)
                ? <>Error</>
                : <>
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <div className={styles.header}>Pacientes</div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <PacientesSearch setSearchString={setSearchString} />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <div className={styles.filterContainer}>
                        <LimiteFilter
                          name='Pacientes'
                          onChange={(lim) => setSearchLimite(lim)}
                          value={searchLimite}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive">
                        <table className="table align-middle">
                          <thead>
                            <tr>
                              <th scope="col">Paciente</th>
                              <th scope="col">Edad</th>
                              <th scope="col">Diagnóstico</th>
                              <th scope="col">Última consulta</th>
                              <th scope="col">Status App</th>
                              <th scope="col">última conexión</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                          { (isFetching)
                          ? <tr><td colSpan={6} className='text-center'>Buscando...</td></tr>
                          : pacientes.map(paciente => <tr key={ paciente._id } onClick={() => handleViewPaciente(paciente._id)}>
                              <td>{ paciente.nombre }</td>
                              <td>{ paciente.edad }</td>
                              <td>-</td>
                              <td>-</td>
                              <td><span className="badge" style={{backgroundColor: paciente.appStatusColor}}>{ paciente.appStatusString }</span></td>
                              <td>-</td>
                              <td>
                                <IconButton
                                  icon='ChevronRight'
                                  iconColor='#666666'
                                  marginTop={false}
                                  onClick={() => handleViewPaciente(paciente._id)}
                                  type='button'
                                />
                              </td>
                            </tr>) }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className='d-flex justify-content-center align-items-center'>
                        <div>
                          <PaginationButton
                            disabled={!(pagina > 1)}
                            onClick={prevPage}
                            type='prev'
                          />
                        </div>
                        <div>{ desde } - { hasta } de { total }</div>
                        <div>
                          <PaginationButton
                            disabled={!(pagina < total_paginas)}
                            onClick={nextPage}
                            type='next'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                }
              </>
            }
          </div>
        </div>
      </div>
      <div className="row"> 
        <div className="col">
          <div className={styles.nuevoPacienteBtnContainer}>
            <PrimaryButton
              icon="Plus"
              inverse={true}
              marginTop={false}
              onClick={handleCrearPaciente}
              text="Nuevo Paciente"
              type="button"
            />
          </div>
        </div>
      </div>
    </>
  )
}
