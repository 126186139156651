import { useState } from 'react';

import { useConsultorios } from './hooks/useConsultorios';

import { PrimaryButton } from '../../components';
import { Consultorio } from './components/Consultorio';
import { ConsultorioCreate } from './components/ConsultorioCreate';

import styles from './Consultorios.module.css';

export const Consultorios = () => {

  const { consultoriosQuery: { isLoading, isError, data: consultorios } } = useConsultorios();

  const [consultorioCreateStatus, setConsultorioCreateStatus] = useState(false);

  const handleCrearConsultorio = () => {
    setConsultorioCreateStatus(true);
  }

  const handleCancelarCrearConsultorio = () => {
    setConsultorioCreateStatus(false);
  }

  return (
    <>
      <div className={styles.header}>Consultorios</div>
      {(isLoading)
        ? <>Cargando...</>
        : <>
            {(isError)
            ? <>Error</>
            : <>
              {consultorios.map(consultorio => <Consultorio key={consultorio._id} consultorio={consultorio} />)}
              {(consultorioCreateStatus)
              ? <>
                <ConsultorioCreate handleCancelarCrearConsultorio={handleCancelarCrearConsultorio} />
              </>
              : <>
                <div className='d-flex justify-content-end'>
                  <PrimaryButton
                    icon="Plus"
                    inverse={true}
                    marginTop={false}
                    onClick={handleCrearConsultorio}
                    text="Agregar Consultorio"
                    type="button"
                  />
                </div>
              </>
              }
            </>
            }
        </>
      }
    </>
  )
}
