const tableCss = `
th{
    font-weight: 400 !important;
}
tr{
    border-color: var(--panel-border);
    cursor: pointer;
}
tr:hover{
    background-color: var(--panel-border);
}
td{
    background-color: transparent !important;
}
.tableText{
    color: var(--seondary-text) !important;
}
`;

module.exports = {
    tableCss
}