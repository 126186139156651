import PropTypes from 'prop-types';

import { useForm, useFormValidation } from '../../../hooks';
import { useConsultorios } from '../hooks/useConsultorios';

import { CheckboxInput, ColorInput, EmailInput, ImgCircledInput, LightButton, PrimaryButton, SelectInput, TelInput, TextInput, ZipCodeInput } from "../../../components"
import { eliminarUndefined } from '../../../helpers';

export const ConsultorioUpdate = ({ consultorio, handleCancelarEditarConsultorio }) => {

  const { updateConsultorioMutation: { mutate: updateConsultorio, isPending } } = useConsultorios();

  const { nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo, onInputChange } = useForm({ nombre: consultorio.nombre, direccion: consultorio.direccion, info: (typeof consultorio.info !== 'undefined') ? consultorio.info : '', colonia: consultorio.colonia, municipio: consultorio.municipio, entidadFederativa: consultorio.entidadFederativa, pais: consultorio.pais, codigoPostal: consultorio.codigoPostal, email: consultorio.email, telefono: (typeof consultorio.telefono !== 'undefined') ? consultorio.telefono : '', celular: consultorio.celular, principal: consultorio.principal, color: consultorio.color, img: consultorio.img, activo: consultorio.activo });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: true}, {field: 'direccion', default: true}, {field: 'info', default: true}, {field: 'colonia', default: true}, {field: 'municipio', default: true}, {field: 'entidadFederativa', default: true}, {field: 'pais', default: true}, {field: 'codigoPostal', default: true}, {field: 'email', default: true}, {field: 'telefono', default: true}, {field: 'celular', default: true}, {field: 'principal', default: true}, {field: 'color', default: true}, {field: 'img', default: true}, {field: 'activo', default: true}]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
      updateConsultorio({ data: eliminarUndefined({ nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo }, { info: consultorio.info, telefono: consultorio.telefono }, ['img']), id: consultorio._id, callback: handleCancelarEditarConsultorio });
    }else{
      startFormValidation();
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12 col-lg-2">
            <ImgCircledInput
              formFieldStatus={ formFields['img'].status }
              imgText="Logo"
              label="Logotipo"
              name="img"
              onChange={ onInputChange }
              required={false}
              requiredIndicator={false}
              setFormFieldValidation={ setFormFieldValidation }
              value={img}
            />
          </div>
          <div className="col-md-12 col-lg-10">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <TextInput
                  formFieldStatus={ formFields['nombre'].status }
                  label="Nombre del consultorio"
                  name="nombre"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ nombre }
                />
              </div>
              <div className="col-sm-12 col-md d-flex align-items-center">
                <CheckboxInput
                  label="Consultorio principal"
                  name="principal"
                  onChange={ onInputChange }
                  required={false}
                  checked={ (principal === 'true' || principal === true) ? true : false }
                />
              </div>
              <div className="col-sm-12 col-md d-flex align-items-center">
                <CheckboxInput
                  label="Consultorio activo"
                  name="activo"
                  onChange={ onInputChange }
                  required={false}
                  checked={ (activo === 'true' || activo === true) ? true : false }
                />
              </div>
              <div className="col-sm-12 col-md">
                <ColorInput
                  formFieldStatus={ formFields['color'].status }
                  label="Color de tema"
                  name="color"
                  onChange={ onInputChange }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ color }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['direccion'].status }
                  label="Dirección"
                  name="direccion"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ direccion }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['info'].status }
                  label="Info. adicional (Piso, # Consultorio, etc.)"
                  name="info"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ info }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['colonia'].status }
                  label="Colonia"
                  name="colonia"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ colonia }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['municipio'].status }
                  label="Municipio"
                  name="municipio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ municipio }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <SelectInput
                  formFieldStatus={ formFields['entidadFederativa'].status }
                  label="Entidad federativa"
                  name="entidadFederativa"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ entidadFederativa }
                  values={ ['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'] }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['pais'].status }
                  label="País"
                  name="pais"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ pais }
                />
              </div>
              <div className="col-sm-12 col-md">
                <ZipCodeInput
                  formFieldStatus={ formFields['codigoPostal'].status }
                  label="Código postal"
                  name="codigoPostal"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ codigoPostal }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <EmailInput
                  formFieldStatus={ formFields['email'].status }
                  label="Correo electrónico"
                  name="email"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ email }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TelInput
                  formFieldStatus={ formFields['telefono'].status }
                  label="Teléfono fijo"
                  name="telefono"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ telefono }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TelInput
                  formFieldStatus={ formFields['celular'].status }
                  label="Teléfono móvil"
                  name="celular"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ celular }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <div className='d-flex justify-content-end'>
                  <LightButton
                      disabled={isPending || formValidationStatus === 'checking'}
                      icon="X"
                      marginRight={true}
                      onClick={handleCancelarEditarConsultorio}
                      text="Cancelar"
                      type="button"
                  />
                  <PrimaryButton
                      disabled={isPending || formValidationStatus === 'checking'}
                      icon="Check"
                      text="Guardar"
                      type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

ConsultorioUpdate.propTypes = {
  consultorio: PropTypes.object.isRequired,
  handleCancelarEditarConsultorio: PropTypes.func.isRequired
}