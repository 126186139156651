import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm, useFormValidation } from '../../../hooks';
import { useRadiologia } from '../hooks/useRadiologia';
import { usePacienteRadiologiaCrear } from '../hooks/usePacienteRadiologiaCrear';

import { DateInput, LightButton, MultiFormArchivos, MultiFormEstudios, PrimaryButton, TextAreaInput } from '../../../components';

import styles from '../PacienteConsulta.module.css';

export const RadiologiaNuevo = ({ id }) => {

    const { radiologiaQuery: { isLoading, isError, data: radiologia } } = useRadiologia();

    const { createRadiologiaMutation: { mutate: createRadiologia, isPending } } = usePacienteRadiologiaCrear(id);

    const [resetFields, setResetFields] = useState(moment().unix());

    const [isClearable, setIsClearable] = useState(false);

    const { estudios, fecha, comentarios, archivos, onInputChange, onResetForm } = useForm({ estudios: [{_id: moment().unix(), estudio: ''}], fecha: '', comentarios: '', archivo: null, archivos: [{id: moment().unix(), archivo: null}] });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation, onResetValidation } = useFormValidation([{field: 'estudios', default: false}, {field: 'fecha', default: false}, {field: 'comentarios', default: true}]);

    const handleOnFieldChange = (e) => {
        setIsClearable(true);
        onInputChange(e);
    }

    const handleClearForm = () => {
        setResetFields(moment().unix());
        onResetForm();
        onResetValidation();
        setIsClearable(false);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            createRadiologia({ data: { estudios: JSON.stringify(estudios.filter(estudio => estudio.estudio !== "").map(estudio => estudio.estudio)), fecha, comentarios, archivos: archivos.filter(archivo => archivo.archivo !== null).map(archivo => archivo.archivo) }, id, success:  handleClearForm});
        }else{
            startFormValidation();
        }
    }

    return (
        <>
            {(isLoading)
                ? <>Cargando...</>
                : <>
                    {(isError)
                        ? <>Error</>
                        : <>
                            <div className="row">
                                <div className="col">
                                    <div className={styles.estudioContainer}>
                                        <form onSubmit={onSubmit}>
                                            <div className="row">
                                                <div className="col">
                                                    <DateInput
                                                        formFieldStatus={ formFields['fecha'].status }
                                                        label="Fecha"
                                                        name="fecha"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        reset={resetFields}
                                                        setFormFieldValidation={ setFormFieldValidation }
                                                        value={ fecha }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <MultiFormEstudios
                                                        formsStatus={ formFields['estudios'].status }
                                                        laboratorio={radiologia}
                                                        name="estudios"
                                                        onFormsChange={ handleOnFieldChange }
                                                        reset={resetFields}
                                                        setFormValidation={ setFormFieldValidation }
                                                        value={estudios}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <MultiFormArchivos
                                                        formsStatus="checked"
                                                        name="archivos"
                                                        onFormsChange={ handleOnFieldChange }
                                                        reset={resetFields}
                                                        value={archivos}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <TextAreaInput
                                                        formFieldStatus={ formFields['comentarios'].status }
                                                        label="Comentarios"
                                                        name="comentarios"
                                                        onChange={ handleOnFieldChange }
                                                        required={ false }
                                                        requiredIndicator={ false }
                                                        setFormFieldValidation={ setFormFieldValidation }
                                                        value={ comentarios }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">
                                                    <LightButton
                                                        block={true}
                                                        disabled={isPending || formValidationStatus === 'checking' || !isClearable}
                                                        icon="X"
                                                        marginRight={true}
                                                        marginTop={false}
                                                        onClick={handleClearForm}
                                                        text="Cancelar"
                                                        type="button"
                                                    />
                                                </div>
                                                <div className="col-md-12 col-lg-6 mt-md-3 mt-lg-0">
                                                    <PrimaryButton
                                                        block={true}
                                                        disabled={isPending || formValidationStatus === 'checking'}
                                                        icon="Check"
                                                        marginTop={false}
                                                        text="Guardar"
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

RadiologiaNuevo.propTypes = {
    id: PropTypes.string.isRequired,
}