import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const TextInputPreview = ({ label, name, value}) => {
  
  return (
    <>
      <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }</label>
      <div className={styles.customPreview}>{ value }</div>
    </>
  )
}

TextInputPreview.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}