import { useState } from 'react';

export const useFormValidation = (formFields = []) => {

    let formFiledsAux = {};
    let formFieldsToValidate = {};
    let isFormValid = true;
    formFields.forEach(data => {
        formFiledsAux[data.field] = {
            status: 'checked', // 'checked', 'checking'
            isValid: data.default
        };
        formFieldsToValidate[data.field] = {
            status: 'checking', // 'checked', 'checking'
            isValid: null
        };
        if (!data.default) {
            isFormValid = false;
        }
    });

    const [formValidation, setFormValidation] = useState({
        isFormValid: isFormValid,
        formValidationStatus: 'checked', // 'checked', 'checking'
        formFields: formFiledsAux
    });

    const startFormValidation = () => {

        setFormValidation({
            ...formValidation,
            isFormValid: true,
            formValidationStatus: 'checking',
            formFields: {
                ...formFieldsToValidate,
            },
        });

    }

    const setFormFieldValidation = (name, value) => {

        let isFormValid = value;
        let formValidationStatus = 'checked';
        Object.keys(formValidation.formFields).forEach(field => {
            if (field !== name) {
                if (formValidation.formFields[field].isValid !== true) {
                    isFormValid = false;
                }
                if (formValidation.formFields[field].status === 'checking') {
                    formValidationStatus = 'checking';
                }
            }
        });

        setFormValidation({
            ...formValidation,
            isFormValid: isFormValid,
            formValidationStatus: formValidationStatus,
            formFields: {
                ...formValidation.formFields,
                [name]: {
                    ...formValidation.formFields[name],
                    status: 'checked',
                    isValid: (value === true),
                },
            },
        });

    }

    const onResetValidation = () => {
        setFormValidation({
            isFormValid: isFormValid,
            formValidationStatus: 'checked', // 'checked', 'checking'
            formFields: formFiledsAux
        });
    }

    return {
        ...formValidation,
        formValidation,
        startFormValidation,
        setFormFieldValidation,
        onResetValidation,
    }
}