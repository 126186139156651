import React from 'react'

import { useDatosGenerales } from './hooks/useDatosGenerales';

import { DatosGeneralesUpdate } from './components/DatosGeneralesUpdate';

export const DatosGenerales = () => {

    const { datosGeneralesQuery: { isLoading, isError, data: datosGenerales } } = useDatosGenerales();

    return (
        <>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <DatosGeneralesUpdate datosGenerales={datosGenerales} />
                </>
                }
            </>
            }
        </>
    )
}
