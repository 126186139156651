const colors = {

    PRIMARY: '#4D96D9',
    INPUT_FEEDBACK: '#4A4A4A',
    DANGER: '#F34336',
    PANEL_BORDER: '#C7D4DD',
    CHART_COLOR_1: '#89AB3B',
    CHART_COLOR_2: '#4D96D9',

}

module.exports = {
    colors
}