import React from 'react'

import { useSubcutanea } from './hooks/useSubcutanea';

import { SubcutaneaUpdate } from './components/SubcutaneaUpdate';

export const Subcutanea = () => {

    const { subcutaneaQuery: { isLoading, isError, data: subcutanea } } = useSubcutanea();

    return (
        <>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <SubcutaneaUpdate subcutaneaAux={subcutanea} />
                </>
                }
            </>
            }
        </>
    )
}
