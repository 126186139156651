import { useDatosGenerales } from '../hooks/useDatosGenerales';

import { FileInputUploader } from '../../../components';

import styles from '../Perfil.module.css';

export const Cedulas = () => {

    const { datosGeneralesQuery: { isLoading, isError, data: usuario }, updateCedulasMutation: { mutate: updateCedulas, isPending } } = useDatosGenerales();

    const uploadCedulaMedicinaGeneral = ( archivo ) => {
        
        updateCedulas({ data: { cedulaMedicinaGeneral: archivo } });
    
    }

    const uploadCedulaEspecialidad = ( archivo ) => {
        
        updateCedulas({ data: { cedulaEspecialidad: archivo } });
    
    }

    const uploadCedulaAlergia = ( archivo ) => {
        
        updateCedulas({ data: { cedulaAlergia: archivo } });
    
    }

    const uploadCedulaConsejoAlergia = ( archivo ) => {
        
        updateCedulas({ data: { cedulaConsejoAlergia: archivo } });
    
    }

    return (
        <>
            <div className={styles.subHeader}>Cédulas</div>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <FileInputUploader
                        defaultValue={ usuario.cedulaMedicinaGeneralName }
                        disabled={ isPending }
                        label="Medicina general"
                        name="cedulaMedicinaGeneral"
                        uploadFn={uploadCedulaMedicinaGeneral}
                    />
                    <FileInputUploader
                        defaultValue={ usuario.cedulaEspecialidadName }
                        disabled={ isPending }
                        label="Especialidad"
                        name="cedulaEspecialidad"
                        uploadFn={uploadCedulaEspecialidad}
                    />
                    <FileInputUploader
                        defaultValue={ usuario.cedulaAlergiaName }
                        disabled={ isPending }
                        label="Alergía"
                        name="cedulaAlergia"
                        uploadFn={uploadCedulaAlergia}
                    />
                    <FileInputUploader
                        defaultValue={ usuario.cedulaConsejoAlergiaName }
                        disabled={ isPending }
                        label="Consejo de alergía"
                        name="cedulaConsejoAlergia"
                        uploadFn={uploadCedulaConsejoAlergia}
                    />
                </>
                }
            </>
            }
        </>
    )
}
