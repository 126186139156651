import React from 'react'

import { useAlergoide } from './hooks/useAlergoide';

import { AlergoideUpdate } from './components/AlergoideUpdate';

export const Alergoide = () => {

    const { alergoideQuery: { isLoading, isError, data: alergoide } } = useAlergoide();

    return (
        <>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <AlergoideUpdate alergoideAux={alergoide} />
                </>
                }
            </>
            }
        </>
    )
}
