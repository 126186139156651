import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteLaboratorio } from '../hooks/usePacienteLaboratorio';

import { ModalContainer, MultiTextInputPreview, PrimaryButton, TextInputPreview } from '../../../components';
import { DetalleLabRadModal } from '../../Modals/DetalleLabRad';

export const LaboratorioPrevio = ({ id }) => {

  const { pacienteLaboratorioQuery: { isLoading, isError, data: laboratorios } } = usePacienteLaboratorio(id);

  const [laboratorioActivo, setLaboratorioActivo] = useState(null);

  const [statusDetallesModal, setStatusDetallesModal] = useState(false);

  const showDetallesModal = () => {
    setStatusDetallesModal(true);
  }

  const hideDetallesModal = () => {
    setStatusDetallesModal(false);
  }

  const handleDetalles = (lab) => {
    setLaboratorioActivo(lab);
    showDetallesModal();
  }
  
  return (
    <>
    {(isLoading)
      ? <>Cargando...</>
      : <>
          {(isError)
            ? <>Error</>
            : <>
              {laboratorios.map(lab => <div key={lab._id}>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <TextInputPreview
                        label="Fecha"
                        name="fecha"
                        value={lab.fechaFormato}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="d-flex justify-content-end">
                      <PrimaryButton
                        inverse={true}
                        marginTop={false}
                        onClick={() => handleDetalles(lab)}
                        text='Ver Detalles'
                        type='button'
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <MultiTextInputPreview
                      label="Estudios"
                      name="estudios"
                      values={lab.estudios}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TextInputPreview
                        label="Comentarios"
                        name="cometarios"
                        value={(lab.comentarios !== '') ? lab.comentarios : '-'}
                    />
                  </div>
                </div>
              </div>)}
              <ModalContainer
                state={ statusDetallesModal }
                hide={ hideDetallesModal }
                content={ <DetalleLabRadModal hide={ hideDetallesModal } data={(laboratorioActivo !== null) ? laboratorioActivo : {}} /> }
              />
            </>
          }
      </>
    }
    </>
  )
}

LaboratorioPrevio.propTypes = {
  id: PropTypes.string.isRequired,
}