import React from 'react'

import { useSublingual } from './hooks/useSublingual';

import { SublingualUpdate } from './components/SublingualUpdate';

export const Sublingual = () => {

    const { sublingualQuery: { isLoading, isError, data: sublingual } } = useSublingual();

    return (
        <>
            {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                ? <>Error</>
                : <>
                    <SublingualUpdate sublingualAux={sublingual} />
                </>
                }
            </>
            }
        </>
    )
}
