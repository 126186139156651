import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteRadiologia } from '../hooks/usePacienteRadiologia';

import { ModalContainer, MultiTextInputPreview, PrimaryButton, TextInputPreview } from '../../../components';
import { DetalleLabRadModal } from '../../Modals/DetalleLabRad';

export const RadiologiaPrevio = ({ id }) => {

  const { pacienteRadiologiaQuery: { isLoading, isError, data: radiologias } } = usePacienteRadiologia(id);

  const [radiologiaActiva, setRadiologiaActiva] = useState(null);

  const [statusDetallesModal, setStatusDetallesModal] = useState(false);

  const showDetallesModal = () => {
    setStatusDetallesModal(true);
  }

  const hideDetallesModal = () => {
    setStatusDetallesModal(false);
  }

  const handleDetalles = (rad) => {
    setRadiologiaActiva(rad);
    showDetallesModal();
  }
  
  return (
    <>
    {(isLoading)
      ? <>Cargando...</>
      : <>
          {(isError)
            ? <>Error</>
            : <>
              {radiologias.map(rad => <div key={rad._id}>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <TextInputPreview
                        label="Fecha"
                        name="fecha"
                        value={rad.fechaFormato}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="d-flex justify-content-end">
                      <PrimaryButton
                        inverse={true}
                        marginTop={false}
                        onClick={() => handleDetalles(rad)}
                        text='Ver Detalles'
                        type='button'
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <MultiTextInputPreview
                      label="Estudios"
                      name="estudios"
                      values={rad.estudios}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TextInputPreview
                        label="Comentarios"
                        name="cometarios"
                        value={(rad.comentarios !== '') ? rad.comentarios : '-'}
                    />
                  </div>
                </div>
              </div>)}
              <ModalContainer
                state={ statusDetallesModal }
                hide={ hideDetallesModal }
                content={ <DetalleLabRadModal hide={ hideDetallesModal } data={(radiologiaActiva !== null) ? radiologiaActiva : {}} /> }
              />
            </>
          }
      </>
    }
    </>
  )
}

RadiologiaPrevio.propTypes = {
  id: PropTypes.string.isRequired,
}