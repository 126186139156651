import { useLoaderData } from 'react-router-dom';

import { usePaciente } from './hooks/usePaciente';
import { useDatosGenerales } from './hooks/useDatosGenerales';

import { PacienteEditarForm } from './components/PacienteEditarForm';

export async function loader({ params }) {
  return params.id;
}

export const PacienteEditar = () => {

  const id = useLoaderData();

  const { pacienteQuery: { isLoading: isLoadingPaciente, isError: isErrorPaciente, data: paciente } } = usePaciente(id);

  const { datosGeneralesQuery: { isLoading: isLoadingConfiguracionDatosGenerales, isError: isErrorConfiguracionDatosGenerales, data: configuracionDatosGenerales } } = useDatosGenerales();

  return (
    <>
      {(isLoadingPaciente || isLoadingConfiguracionDatosGenerales)
        ? <>Cargando...</>
        : <>
          {(isErrorPaciente || isErrorConfiguracionDatosGenerales)
            ? <>Error</>
            : <>
              <PacienteEditarForm paciente={paciente} configuracionDatosGenerales={configuracionDatosGenerales} />
            </>
          }
        </>
      }
    </>
  )
}
