import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm, useFormValidation } from "../../hooks"
import { startLogin } from "../../redux/slices/auth";

import { CheckboxInput, EmailInput, Link, ModalContainer, PasswordInput, PrimaryButton } from "../../components";
import { ResetPasswordModal } from "../Modals/ResetPassword";
import { EmailVerificationModal } from "../Modals/EmailVerification";

import styles from './Login.module.css';

export const Login = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { status } = useSelector(state => state.auth);

  const { email, password, rememberme, onInputChange } = useForm({ email: '', password: '', rememberme: true });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'email', default: false}, {field: 'password', default: false}]);

  const [statusPasswordResetModal, setStatusPasswordResetModal] = useState(false);

  const [statusEmailVerificationModal, setStatusEmailVerificationModal] = useState(false);

  useEffect(() => {
    let loginEmail = localStorage.getItem('loginEmail');
    if(loginEmail !== null){
      onInputChange({ target: { name: 'email', value: loginEmail } });
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSignupRedirection = () => {
    navigate("/signup");
  }

  const showPasswordResetModal = () => {
    setStatusPasswordResetModal(true);
  }
  
  const hidePasswordResetModal = () => {
      setStatusPasswordResetModal(false);
  }

  const showEmailVerificationModal = () => {
    setStatusEmailVerificationModal(true);
  }
  
  const hideEmailVerificationModal = () => {
      setStatusEmailVerificationModal(false);
  }

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    startFormValidation();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
      if(rememberme === 'true' || rememberme === true){
        localStorage.setItem('loginEmail', email);
      }else{
        localStorage.removeItem('loginEmail');
      }
      let res = await dispatch(startLogin(email, password));
      if(res === 'verification'){
        showEmailVerificationModal();
      }
    }

  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className={styles.formHeader}>Bienvenido</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className="col">
                <EmailInput
                  formFieldStatus={ formFields['email'].status }
                  label="Email"
                  name="email"
                  onChange={ onInputChange }
                  placeholder="example@email.com"
                  required={ true }
                  requiredIndicator={false}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ email }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <PasswordInput
                  formFieldStatus={ formFields['password'].status }
                  label="Contraseña"
                  name="password"
                  onChange={ onInputChange }
                  required={true}
                  requiredIndicator={false}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ password }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CheckboxInput
                  label="Recordarme"
                  name="rememberme"
                  onChange={ onInputChange }
                  required={false}
                  checked={ (rememberme === 'true' || rememberme === true) ? true : false }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="float-end">
                  <PrimaryButton
                    disabled={status === 'checking' || formValidationStatus === 'checking'}
                    text="Iniciar Sesión"
                    type="submit"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Link
                  onClick={showPasswordResetModal}
                  text="Olvide la contraseña"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className={styles.registerNavigationContainer}>
                  <div className={"text-center " + styles.formBoldText}>¿No tienes cuenta?</div>
                  <div className="centrar">
                    <PrimaryButton
                      inverse={true}
                      marginTop={false}
                      onClick={handleSignupRedirection}
                      text="Crear cuenta"
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalContainer
        state={ statusPasswordResetModal }
        hide={ hidePasswordResetModal }
        content={ <ResetPasswordModal hide={ hidePasswordResetModal } /> }
      />
      <ModalContainer
        state={ statusEmailVerificationModal }
        hide={ hideEmailVerificationModal }
        content={ <EmailVerificationModal hide={ hideEmailVerificationModal } /> }
      />
    </>
  )
}
