import { inmunetApi } from '../../../api';

export const getConsultorios = async() => {

    try {

        const { data } = await inmunetApi.get('/consultorio?paginacion=true');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.consultorios;

    } catch (error) {

        throw new Error(error);

    }

};

export const createConsultorio = async({ data: consultorio }) => {

    try {

        let formData = new FormData();
        Object.keys(consultorio).forEach(field => {
            formData.append(field, consultorio[field]);
        });

        const { data } = await inmunetApi.post(`/consultorio`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear consultorio';

        return data.consultorio;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};

export const updateConsultorio = async({ data: consultorio, id }) => {

    try {

        let formData = new FormData();
        Object.keys(consultorio).forEach(field => {
            formData.append(field, consultorio[field]);
        });

        const { data } = await inmunetApi.put(`/consultorio/${ id }`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return {
            ...data
        };

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};