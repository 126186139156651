import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { usePaciente } from './hooks/usePaciente';
import { useDatosGenerales } from './hooks/useDatosGenerales';

import { Acordion, ModalContainer, PrimaryButton, TextInputPreview } from '../../components';
import { AsociadoPreview } from './components/AsociadoPreview';

import styles from './PacienteView.module.css';
import { IndependizarPacienteModal } from '../Modals/IndependizarPaciente';

export async function loader({ params }) {
    return params.id;
}

export const PacienteView = () => {

    const navigate = useNavigate();

    const id = useLoaderData();

    const { pacienteQuery: { isLoading: isLoadingPaciente, isError: isErrorPaciente, data: paciente } } = usePaciente(id);

    const { datosGeneralesQuery: { isLoading: isLoadingConfiguracionDatosGenerales, isError: isErrorConfiguracionDatosGenerales, data: configuracionDatosGenerales } } = useDatosGenerales();

    const [statusIndependizarPacienteModal, setStatusIndependizarPacienteModal] = useState(false);

    const [pacienteAsociado, setPacienteAsociado] = useState({});

    const handleIndependizar = (asociado) => {
        setPacienteAsociado(asociado);
        setStatusIndependizarPacienteModal(true);
    }
    
    const hideCancelarIndependizar = () => {
        setStatusIndependizarPacienteModal(false);
        setPacienteAsociado({});
    }

    const handleCrearPacienteAsociado = () => {
        navigate(`/paciente/nuevo/${paciente._id}`);
    }

    return (
        <>
        {(isLoadingPaciente || isLoadingConfiguracionDatosGenerales)
            ? <>Cargando...</>
            : <>
            {(isErrorPaciente || isErrorConfiguracionDatosGenerales)
                ? <>Error</>
                : <>
                    <div className="row">
                        <div className="col">
                            <div className={styles.panelSection + " " + styles.panelNoHeader}>
                                <div className="row">
                                    <div className="col-md-12 col-lg-8">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-3">
                                                <div className={styles.panel}>
                                                    <TextInputPreview
                                                        label="Próxima cita"
                                                        name="proximaCita"
                                                        value="-"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-3">
                                                <div className={styles.panel}>
                                                    <TextInputPreview
                                                        label="Inmunoterapia actual"
                                                        name="inmunoterapiaActual"
                                                        value="-"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                                <div className={styles.panel}>
                                                    <div>App Móvil</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-4">
                                        <div className={styles.panelDivider}>
                                            <div className="row h-100">
                                                <div className="col h-100">
                                                    <div className={styles.panelHeader}>Usuario Principal</div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <TextInputPreview
                                                                label="Nombre"
                                                                name="nombre"
                                                                value={ paciente.usuario.nombre }
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <TextInputPreview
                                                                label="Correo electrónico"
                                                                name="email"
                                                                value={ paciente.usuario.email }
                                                            />
                                                        </div>
                                                    </div>
                                                    {(typeof paciente.pacientesAsociados !== 'undefined') && <>
                                                        <div className={styles.panelHeader}>Dependientes</div>
                                                        { (paciente.pacientesAsociados.length > 0) ? paciente.pacientesAsociados.map(asociado => <div key={asociado._id}>
                                                            <AsociadoPreview
                                                                asociado={asociado}
                                                                independizar={() => handleIndependizar(asociado)}
                                                            />
                                                        </div>) : <div className='d-flex justify-content-start'>
                                                            <PrimaryButton
                                                                icon="Plus"
                                                                inverse={true}
                                                                marginTop={false}
                                                                onClick={handleCrearPacienteAsociado}
                                                                text="Agregar Paciente"
                                                                type="button"
                                                            />
                                                        </div> }
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection + " " + styles.panelNoHeader}>
                                <div className={styles.subHeader}>Datos Generales</div>
                                <div className="row">
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Paciente"
                                            name="paciente"
                                            value={ [(configuracionDatosGenerales.fichaIdentificacion.nombre) ? paciente.nombre : null, (configuracionDatosGenerales.fichaIdentificacion.apellidoPaterno) ? paciente.apellidoPaterno : null, (configuracionDatosGenerales.fichaIdentificacion.apellidoMaterno && paciente.apellidoMaterno !== '' && paciente.apellidoMaterno !== undefined) ? paciente.apellidoMaterno : null].join(" ") }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Fecha de nacimiento"
                                            name="fechaNacimiento"
                                            value={ paciente.fechaNacimientoFormato }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Edad"
                                            name="edad"
                                            value={ paciente.edad }
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Género"
                                            name="genero"
                                            value={ paciente.genero }
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Correo electrónico"
                                            name="email"
                                            value={ paciente.email }
                                        />
                                    </div>
                                    {(configuracionDatosGenerales.fichaIdentificacion.telefono) &&
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Teléfono fijo"
                                            name="telefono"
                                            value={ (paciente.telefono !== '' && paciente.telefono !== undefined && paciente.telefono !== null) ? paciente.telefono : '-' }
                                        />
                                    </div>
                                    }
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Celular"
                                            name="celular"
                                            value={ paciente.celular }
                                        />
                                    </div>
                                    {(configuracionDatosGenerales.fichaIdentificacion.medioContacto) &&
                                    <div className="col-md-4 col-lg-3">
                                        <TextInputPreview
                                            label="Medio de contacto"
                                            name="medioContacto"
                                            value={ paciente.medioContacto }
                                        />
                                    </div>
                                    }
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Diagnóstico(s)"
                                            name="diagnosticos"
                                            value="-"
                                        />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Última consulta(s)"
                                            name="ultimaConsulta"
                                            value="-"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className={styles.subHeader}>Domicilio</div>
                                <div className="row">
                                    {(configuracionDatosGenerales.domicilio.calle) &&
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Calle"
                                            name="calle"
                                            value={ (paciente.calle !== '' && paciente.calle !== undefined && paciente.calle !== null) ? paciente.calle : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.numero) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="# Exterior"
                                            name="numeroExterior"
                                            value={ (paciente.numeroExterior !== '' && paciente.numeroExterior !== undefined && paciente.numeroExterior !== null) ? paciente.numeroExterior : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.numero) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="# Interior"
                                            name="numeroInterior"
                                            value={ (paciente.numeroInterior !== '' && paciente.numeroInterior !== undefined && paciente.numeroInterior !== null) ? paciente.numeroInterior : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.colonia) &&
                                    <div className="col-md-12 col-lg-3">
                                        <TextInputPreview
                                            label="Colonia"
                                            name="colonia"
                                            value={ (paciente.colonia !== '' && paciente.colonia !== undefined && paciente.colonia !== null) ? paciente.colonia : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.municipio) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Municipio"
                                            name="municipio"
                                            value={ (paciente.municipio !== '' && paciente.municipio !== undefined && paciente.municipio !== null) ? paciente.municipio : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.entidadFederativa) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Entidad federativa"
                                            name="entidadFederativa"
                                            value={ (paciente.entidadFederativa !== '' && paciente.entidadFederativa !== undefined && paciente.entidadFederativa !== null) ? paciente.entidadFederativa : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.pais) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="País"
                                            name="pais"
                                            value={ (paciente.pais !== '' && paciente.pais !== undefined && paciente.pais !== null) ? paciente.pais : '-' }
                                        />
                                    </div>
                                    }
                                    {(configuracionDatosGenerales.domicilio.codigoPostal) &&
                                    <div className="col-md-6 col-lg-3">
                                        <TextInputPreview
                                            label="Código postal"
                                            name="codigoPostal"
                                            value={ (paciente.codigoPostal !== '' && paciente.codigoPostal !== undefined && paciente.codigoPostal !== null) ? paciente.codigoPostal : '-' }
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                <div className="row">
                                                    {(configuracionDatosGenerales.fichaIdentificacion.nacionalidad) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Nacionalidad"
                                                            name="nacionalidad"
                                                            value={ (paciente.nacionalidad !== '' && paciente.nacionalidad !== undefined && paciente.nacionalidad !== null) ? paciente.nacionalidad : '-' }
                                                        />
                                                    </div>
                                                    }
                                                    {(configuracionDatosGenerales.fichaIdentificacion.estadoCivil) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Estado civil"
                                                            name="estadoCivil"
                                                            value={ (paciente.estadoCivil !== '' && paciente.estadoCivil !== undefined && paciente.estadoCivil !== null) ? paciente.estadoCivil : '-' }
                                                        />
                                                    </div>
                                                    }
                                                    {(configuracionDatosGenerales.fichaIdentificacion.ocupacion) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Ocupación"
                                                            name="ocupacion"
                                                            value={ (paciente.ocupacion !== '' && paciente.ocupacion !== undefined && paciente.ocupacion !== null) ? paciente.ocupacion : '-' }
                                                        />
                                                    </div>
                                                    }
                                                    {(configuracionDatosGenerales.fichaIdentificacion.religion) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Religión"
                                                            name="religion"
                                                            value={ (paciente.religion !== '' && paciente.religion !== undefined && paciente.religion !== null) ? paciente.religion : '-' }
                                                        />
                                                    </div>
                                                    }
                                                    {(configuracionDatosGenerales.fichaIdentificacion.origen) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Origen"
                                                            name="origen"
                                                            value={ (paciente.origen !== '' && paciente.origen !== undefined && paciente.origen !== null) ? paciente.origen : '-' }
                                                        />
                                                    </div>
                                                    }
                                                    {(configuracionDatosGenerales.fichaIdentificacion.residencia) &&
                                                    <div className="col-md-6 col-lg-2">
                                                        <TextInputPreview
                                                            label="Residencia"
                                                            name="residencia"
                                                            value={ (paciente.residencia !== '' && paciente.residencia !== undefined && paciente.residencia !== null) ? paciente.residencia : '-' }
                                                        />
                                                    </div>
                                                    }
                                                </div>
                                            </>}
                                            header={<div className={styles.subHeader}>Otros</div>}
                                            id='pacienteViewOtrosAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.panelSection}>
                                <div className="row">
                                    <div className="col">
                                        <Acordion
                                            body={<>
                                                { paciente.tutores.map(tutor => 
                                                    (<div key={tutor._id}>
                                                        <div className={"row " + styles.tutorDivider}>
                                                            {(configuracionDatosGenerales.tutores.parentesco) &&
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Parentesco"
                                                                    name="parentesco"
                                                                    value={ tutor.parentesco }
                                                                />
                                                            </div>
                                                            }
                                                            {(configuracionDatosGenerales.tutores.nombre) &&
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Nombre(s)"
                                                                    name="nombre"
                                                                    value={ tutor.nombre }
                                                                />
                                                            </div>
                                                            }
                                                            {(configuracionDatosGenerales.tutores.email) &&
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Correo electrónico"
                                                                    name="email"
                                                                    value={ tutor.email }
                                                                />
                                                            </div>
                                                            }
                                                            {(configuracionDatosGenerales.tutores.celular) &&
                                                            <div className="col-md-6 col-lg-3">
                                                                <TextInputPreview
                                                                    label="Teléfono móvil"
                                                                    name="celular"
                                                                    value={ tutor.celular }
                                                                />
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>)
                                                ) }
                                            </>}
                                            header={<div className={styles.subHeader}>Tutores</div>}
                                            id='pacienteViewTutoresAcordeon'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalContainer
                        state={ statusIndependizarPacienteModal }
                        hide={ hideCancelarIndependizar }
                        content={ <IndependizarPacienteModal hide={ hideCancelarIndependizar } asociado={pacienteAsociado} principal={paciente.usuario} anterior={paciente._id} /> }
                    />
                </>
            }
            </>
        }
        </>
    )
}
