import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useDatosGenerales } from './hooks/useDatosGenerales';
import { usePacienteNuevo } from './hooks/usePacienteNuevo';
import { useForm, useFormValidation } from '../../hooks';

import { Acordion, DateInput, EmailInput, ModalContainer, MultiFormTutor, SelectInput, TelInput, TextInput, ZipCodeInput } from '../../components';
import { PacienteAsociadoModal } from '../Modals/PacienteAsociado';

import { eliminarUndefined } from '../../helpers/eliminarUndefined';

import styles from './PacienteNuevo.module.css';

export const PacienteNuevo = () => {

  const navigate = useNavigate();

  let { id } = useParams();

  const [submit, setOnSubmit, appActivaContext] = useOutletContext();

  const [originalSubmit] = useState(submit);

  const [statusPacienteAsociadoModal, setStatusPacienteAsociadoModal] = useState(false);

  const [idPacienteAsociadoModal, setIdPacienteAsociadoModal] = useState('');

  const { datosGeneralesQuery: { isLoading, isError, data: configuracionDatosGenerales } } = useDatosGenerales();

  const { createPacienteMutation: { mutate: createPaciente } } = usePacienteNuevo();

  const { nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, telefono, medioContacto, calle, numeroExterior, numeroInterior, colonia, municipio, entidadFederativa, pais, codigoPostal, nacionalidad, estadoCivil, ocupacion, religion, origen, residencia, tutores, appActiva, onInputChange } = useForm({ nombre: '', apellidoPaterno: '', apellidoMaterno: '', fechaNacimiento: '', genero: '', email: '', celular: '', telefono: '', medioContacto: '', calle: '', numeroExterior: '', numeroInterior: '', colonia: '', municipio: '', entidadFederativa: '', pais: '', codigoPostal: '', nacionalidad: '', estadoCivil: '', ocupacion: '', religion: '', origen: '', residencia: '', tutores: [], appActiva: appActivaContext });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: false}, {field: 'apellidoPaterno', default: false}, {field: 'apellidoMaterno', default: true}, {field: 'fechaNacimiento', default: false}, {field: 'genero', default: false}, {field: 'email', default: false}, {field: 'celular', default: false}, {field: 'telefono', default: true}, {field: 'medioContacto', default: true}, {field: 'calle', default: true}, {field: 'numeroExterior', default: true}, {field: 'numeroInterior', default: true}, {field: 'colonia', default: true}, {field: 'municipio', default: true}, {field: 'entidadFederativa', default: true}, {field: 'pais', default: true}, {field: 'codigoPostal', default: true}, {field: 'nacionalidad', default: true}, {field: 'estadoCivil', default: true}, {field: 'ocupacion', default: true}, {field: 'religion', default: true}, {field: 'origen', default: true}, {field: 'residencia', default: true}, {field: 'tutores', default: true}, {field: 'appActiva', default: true}]);

  const showPacienteAsociadoModal = () => {
    setStatusPacienteAsociadoModal(true);
  }

  const hidePacienteAsociadoModal = () => {
    setStatusPacienteAsociadoModal(false);
  }

  const handleOnCompleteSubmit = () => {
    setOnSubmit(false);
  }

  const handleViewPaciente = (id) => {
    navigate(`/paciente/view/${id}`);
  }

  useEffect(() => {
    onInputChange({ target: { name: 'appActiva', value: appActivaContext } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appActivaContext])
  

  useEffect(() => {
    if(submit !== originalSubmit){
      if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
        setOnSubmit(true);
        createPaciente({ data: eliminarUndefined({ nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, telefono, medioContacto, calle, numeroExterior, numeroInterior, colonia, municipio, entidadFederativa, pais, codigoPostal, nacionalidad, estadoCivil, ocupacion, religion, origen, residencia, tutores: JSON.stringify(tutores), appActiva: appActiva, pacienteAsociado: (id !== undefined) ? id : '' }, { apellidoMaterno: undefined, telefono: undefined, medioContacto: undefined, calle: undefined, numeroExterior: undefined, numeroInterior: undefined, colonia: undefined, municipio: undefined, entidadFederativa: undefined, pais: undefined, codigoPostal: undefined, nacionalidad: undefined, estadoCivil: undefined, ocupacion: undefined, religion: undefined, origen: undefined, residencia: undefined, pacienteAsociado: undefined }), onSuccess: handleViewPaciente, onModal: showPacienteAsociadoModal, setIdPacienteAsociadoModal: setIdPacienteAsociadoModal, onComplete: handleOnCompleteSubmit });
      }else{
        startFormValidation();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  const handleModalSubmit = () => {

    setOnSubmit(true);

    createPaciente({ data: eliminarUndefined({ nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, telefono, medioContacto, calle, numeroExterior, numeroInterior, colonia, municipio, entidadFederativa, pais, codigoPostal, nacionalidad, estadoCivil, ocupacion, religion, origen, residencia, tutores: JSON.stringify(tutores), appActiva: appActiva, pacienteAsociado: idPacienteAsociadoModal }, { apellidoMaterno: undefined, telefono: undefined, medioContacto: undefined, calle: undefined, numeroExterior: undefined, numeroInterior: undefined, colonia: undefined, municipio: undefined, entidadFederativa: undefined, pais: undefined, codigoPostal: undefined, nacionalidad: undefined, estadoCivil: undefined, ocupacion: undefined, religion: undefined, origen: undefined, residencia: undefined }), onSuccess: handleViewPaciente, onModal: showPacienteAsociadoModal, setIdPacienteAsociadoModal: setIdPacienteAsociadoModal, onComplete: handleOnCompleteSubmit });

  }

  const [generoOptions, setGeneroOptions] = useState(['Masculino', 'Femenino']);

  const [medioContactoOptions, setMedioContactoOptions] = useState(['No especificado']);

  useEffect(() => {
    let opcionesGenero = ['Masculino', 'Femenino'];
    if(typeof configuracionDatosGenerales !== 'undefined' && typeof configuracionDatosGenerales.fichaIdentificacion !== 'undefined' && typeof configuracionDatosGenerales.fichaIdentificacion.genero !== 'undefined'){
      if(typeof configuracionDatosGenerales.fichaIdentificacion.generoOtro !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.generoOtro){
        opcionesGenero.push('Otro');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.generoPrefieroNoDecirlo !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.generoPrefieroNoDecirlo){
        opcionesGenero.push('Prefiero no decirlo');
      }
    }
    setGeneroOptions(opcionesGenero);
    let opcionesMedioContacto = ['No especificado'];
    if(typeof configuracionDatosGenerales !== 'undefined' && typeof configuracionDatosGenerales.fichaIdentificacion !== 'undefined' && typeof configuracionDatosGenerales.fichaIdentificacion.medioContacto !== 'undefined'){
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoInstagram !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoInstagram){
        opcionesMedioContacto.push('Instagram');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoFacebook !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoFacebook){
        opcionesMedioContacto.push('⁠Facebook');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoTiktok !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoTiktok){
        opcionesMedioContacto.push('⁠Tiktok');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoPaginaWeb !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoPaginaWeb){
        opcionesMedioContacto.push('⁠Pagina web');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaOtroDoctor !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaOtroDoctor){
        opcionesMedioContacto.push('⁠Referencia otro doctor');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaFamiliar !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaFamiliar){
        opcionesMedioContacto.push('⁠Referencia familiar');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaAmistad !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoReferenciaAmistad){
        opcionesMedioContacto.push('⁠Referencia amistad');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoCentroMedico !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoCentroMedico){
        opcionesMedioContacto.push('⁠Centro médico/hospital');
      }
      if(typeof configuracionDatosGenerales.fichaIdentificacion.medioContactoOtro !== 'undefined' && configuracionDatosGenerales.fichaIdentificacion.medioContactoOtro){
        opcionesMedioContacto.push('⁠Otro');
      }
    }
    setMedioContactoOptions(opcionesMedioContacto);
  }, [configuracionDatosGenerales])
  
  return (
    <>
      {(isLoading)
        ? <>Cargando...</>
        : <>
          {(isError)
            ? <>Error</>
            : <>
              <div className="row">
                <div className="col">
                  <div className={styles.panel + " " + styles.panelNoHeader}>
                    <div className={styles.header}>Nuevo Paciente</div>
                    <form>
                      <div className={styles.panelSection}>
                        <div className={styles.subHeader}>Datos Generales</div>
                        <div className="row">
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['nombre'].status }
                              label="Nombre(s)"
                              name="nombre"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ nombre }
                            />
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['apellidoPaterno'].status }
                              label="Apellido paterno"
                              name="apellidoPaterno"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ apellidoPaterno }
                            />
                          </div>
                          {(configuracionDatosGenerales.fichaIdentificacion.apellidoMaterno) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['apellidoMaterno'].status }
                              label="Apellido materno"
                              name="apellidoMaterno"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ apellidoMaterno }
                            />
                          </div>
                          }
                          <div className="col-md-12 col-lg-4">
                            <DateInput
                              formFieldStatus={ formFields['fechaNacimiento'].status }
                              label="Fecha de nacimiento"
                              name="fechaNacimiento"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ fechaNacimiento }
                            />
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <SelectInput
                              formFieldStatus={ formFields['genero'].status }
                              label="Género"
                              name="genero"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ genero }
                              values={ generoOptions }
                            />
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <EmailInput
                              formFieldStatus={ formFields['email'].status }
                              label="Correo electrónico"
                              name="email"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ email }
                            />
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <TelInput
                              formFieldStatus={ formFields['celular'].status }
                              label="Teléfono móvil"
                              name="celular"
                              onChange={ onInputChange }
                              required={ true }
                              requiredIndicator={ true }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ celular }
                            />
                          </div>
                          {(configuracionDatosGenerales.fichaIdentificacion.telefono) &&
                          <div className="col-md-12 col-lg-4">
                            <TelInput
                              formFieldStatus={ formFields['telefono'].status }
                              label="Teléfono fijo"
                              name="telefono"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ telefono }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.fichaIdentificacion.medioContacto) &&
                          <div className="col-md-12 col-lg-4">
                            <SelectInput
                              formFieldStatus={ formFields['medioContacto'].status }
                              label="Medio de contacto"
                              name="medioContacto"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ medioContacto }
                              values={ medioContactoOptions }
                            />
                          </div>
                          }
                        </div>
                      </div>
                      <div className={styles.panelSection}>
                        <div className={styles.subHeader}>Domicilio</div>
                        <div className="row">
                          {(configuracionDatosGenerales.domicilio.calle) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['calle'].status }
                              label="Calle"
                              name="calle"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ calle }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.numero) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['numeroExterior'].status }
                              label="# Exterior"
                              name="numeroExterior"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ numeroExterior }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.numero) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['numeroInterior'].status }
                              label="# Interior"
                              name="numeroInterior"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ numeroInterior }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.colonia) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['colonia'].status }
                              label="Colonia"
                              name="colonia"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ colonia }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.municipio) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['municipio'].status }
                              label="Municipio"
                              name="municipio"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ municipio }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.entidadFederativa) &&
                          <div className="col-md-12 col-lg-4">
                            <SelectInput
                              formFieldStatus={ formFields['entidadFederativa'].status }
                              label="Entidad federativa"
                              name="entidadFederativa"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ entidadFederativa }
                              values={ ['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'] }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.pais) &&
                          <div className="col-md-12 col-lg-4">
                            <TextInput
                              formFieldStatus={ formFields['pais'].status }
                              label="País"
                              name="pais"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ pais }
                            />
                          </div>
                          }
                          {(configuracionDatosGenerales.domicilio.codigoPostal) &&
                          <div className="col-md-12 col-lg-4">
                            <ZipCodeInput
                              formFieldStatus={ formFields['codigoPostal'].status }
                              label="Código postal"
                              name="codigoPostal"
                              onChange={ onInputChange }
                              required={ false }
                              requiredIndicator={ false }
                              setFormFieldValidation={ setFormFieldValidation }
                              value={ codigoPostal }
                            />
                          </div>
                          }
                        </div>
                      </div>
                      <div className={styles.panelSection}>
                        <div className="row">
                          <div className="col">
                            <Acordion
                              body={<>
                                <div className="row">
                                  {(configuracionDatosGenerales.fichaIdentificacion.nacionalidad) &&
                                  <div className="col-md-12 col-lg-4">
                                    <TextInput
                                      formFieldStatus={ formFields['nacionalidad'].status }
                                      label="Nacionalidad"
                                      name="nacionalidad"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ nacionalidad }
                                    />
                                  </div>
                                  }
                                  {(configuracionDatosGenerales.fichaIdentificacion.estadoCivil) &&
                                  <div className="col-md-12 col-lg-4">
                                    <SelectInput
                                      formFieldStatus={ formFields['estadoCivil'].status }
                                      label="Estado civil"
                                      name="estadoCivil"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ estadoCivil }
                                      values={ ['Soltero', 'Casado', 'Viudo', 'Divorciado', 'Unión libre'] }
                                    />
                                  </div>
                                  }
                                  {(configuracionDatosGenerales.fichaIdentificacion.ocupacion) &&
                                  <div className="col-md-12 col-lg-4">
                                    <TextInput
                                      formFieldStatus={ formFields['ocupacion'].status }
                                      label="Ocupación"
                                      name="ocupacion"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ ocupacion }
                                    />
                                  </div>
                                  }
                                  {(configuracionDatosGenerales.fichaIdentificacion.religion) &&
                                  <div className="col-md-12 col-lg-4">
                                    <TextInput
                                      formFieldStatus={ formFields['religion'].status }
                                      label="Religión"
                                      name="religion"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ religion }
                                    />
                                  </div>
                                  }
                                  {(configuracionDatosGenerales.fichaIdentificacion.origen) &&
                                  <div className="col-md-12 col-lg-4">
                                    <TextInput
                                      formFieldStatus={ formFields['origen'].status }
                                      label="Origen"
                                      name="origen"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ origen }
                                    />
                                  </div>
                                  }
                                  {(configuracionDatosGenerales.fichaIdentificacion.residencia) &&
                                  <div className="col-md-12 col-lg-4">
                                    <TextInput
                                      formFieldStatus={ formFields['residencia'].status }
                                      label="Residencia"
                                      name="residencia"
                                      onChange={ onInputChange }
                                      required={ false }
                                      requiredIndicator={ false }
                                      setFormFieldValidation={ setFormFieldValidation }
                                      value={ residencia }
                                    />
                                  </div>
                                  }
                                </div>
                              </>}
                              header={<div className={styles.subHeader}>Otros</div>}
                              id='pacienteNuevoOtrosAcordeon'
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.panelSection}>
                        <div className="row">
                          <div className="col">
                          <Acordion
                              body={<>
                                <div className="row">
                                  <div className="col">
                                    <MultiFormTutor
                                      formsStatus={ formFields['tutores'].status }
                                      name="tutores"
                                      onFormsChange={ onInputChange }
                                      setFormValidation={ setFormFieldValidation }
                                    />
                                  </div>
                                </div>
                              </>}
                              header={<div className={styles.subHeader}>Tutores</div>}
                              id='pacienteNuevoTutoresAcordeon'
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <ModalContainer
                state={ statusPacienteAsociadoModal }
                hide={ hidePacienteAsociadoModal }
                content={ <PacienteAsociadoModal hide={ hidePacienteAsociadoModal } submit={handleModalSubmit} /> }
              />
            </>
          }
        </>
      }        
    </>
  )
}
